<template>
  <div class="">
    <div class="gs-info">
      <div class="gi-left">
        <div class="gl-pic">
          <img
            :src="
              userInfo.avatar
                ? 'https://freemen.work/user/file/view/' + userInfo.avatar
                : 'https://freemen.work/user/file/view/files/20220311/ic_launcher_gray_1646968576594.png'
            "
          />
        </div>
        <div class="gl-info font14">
          <div class="font20">
            {{ userInfo.displayName }}
            <span>{{
              userInfo.idStatus == -1
                ? "未实名"
                : userInfo.idStatus == 0
                ? "未实名"
                : "已实名"
            }}</span>
          </div>
          <div class="zaizhi">
            <el-dropdown @command="changeWorkStatus">
              <span
                class="zli"
                style="
                  background-color: #646d83;
                  color: #ffffff;
                  font-size: 14px;
                  padding: 2px 6px;
                  border-radius: 2px;
                  border: none;
                "
              >
                {{ userData.workStatus == 1 ? "在职" : "求职"
                }}<i class="el-icon-caret-bottom"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="userData.workStatus == 1"
                  >在职</el-dropdown-item
                >
                <el-dropdown-item :disabled="userData.workStatus == 0"
                  >求职</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="" @click="setTime">
            期望工作时间：{{ start ? start : "设置开始" }}
            <span>-</span>
            {{ end ? end : "设置结束" }}
          </div>
        </div>
      </div>
      <el-divider direction="vertical"></el-divider>
      <div class="gi-right">
        <div class="gr-main" @click="$router.push('/deliveryrecord')">
          <div class="font26">{{ userData.postCv }}</div>
          <div class="font14">投递记录</div>
        </div>
        <div class="gr-main" @click="$router.push('/Interviewmanagement')">
          <div class="font26">{{ userData.interview }}</div>
          <div class="font14">面试邀约</div>
        </div>
        <div class="gr-main" @click="$router.push('/offermanagement')">
          <div class="font26">{{ userData.offer }}</div>
          <div class="font14">Offer管理</div>
        </div>
      </div>
    </div>
    <div class="tuijian">
      <div class="t-one">
        <div class="font24">推荐职位</div>
        <div class="more font16" @click="$router.push('/positionindex')">
          更多推荐职位
        </div>
      </div>
      <div class="t-two font14">根据求职意向推荐</div>
    </div>
    <div class="">
      <el-table
        :data="jobList"
        stripe
        border
        :show-header="false"
        element-table_loading-text="加载中"
        class="boderline"
        style="width: 100%"
      >
        <el-table-column prop="jobName"></el-table-column>
        <el-table-column prop="shortName"></el-table-column>
        <el-table-column prop="city"></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="">
              <span class="zhang">
                {{ (scope.row.salaryLow / 1000) | numFilter }}
                 K -
                {{ (scope.row.salaryHigh / 1000) | numFilter}}
                 K
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="">
              <span class="time">{{ scope.row.updateDate }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="">
              <a
                @click="toDetail(scope.row.id)"
                style="color: #04bdff; text-decoration: none"
                href="#"
                >查看职位</a
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="batchDialogVisible"
      width="700px"
      center
      :show-close="false"
      :modal-append-to-body="false"
      class="batch-box-out"
    >
      <div class="batch-box">
        <span class="closeicon" @click="closebatchDialogbox()">
          <i class="el-icon-error"></i>
        </span>
        <div class="wb-tit font18">设置期望工作时间</div>
        <div class="frombox">
          <el-form
            :model="batchForm"
            ref="batchForm"
            size="small"
            label-width="130px"
            class="demo-ruleForm"
          >
            <el-form-item label="期望工作时间：" prop="startDate">
              <el-date-picker
                type="date"
                :picker-options="pickerOptions0"
                placeholder="起"
                v-model="batchForm.startDate"
                style="width: 184px"
              ></el-date-picker>
              <span style="margin: 0 13px">-</span>
              <el-date-picker
                type="date"
                :picker-options="pickerOptions1"
                placeholder="止"
                v-model="batchForm.endDate"
                style="width: 184px"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="btn-next"
                @click="toliucheng('batchForm')"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      batchDialogVisible: false,
      batchForm: {
        startDate: "",
        endDate: "",
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //如果没有后面的-8.64e7就是不可以选择今天的
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return (
            time.getTime() <
            new Date(this.batchForm.start).getTime() + 1 * 24 * 60 * 60 * 1000
          ); //减去一天的时间代表可以选择同一天;
        },
      },
      tableData: [
        {
          zhiwei: "Java后端开发",
          name: "杭州最田源科技有限公司",
          address: "杭州",
          xinzi: "12-15K",
          time: "2021-07-18更新",
        },
        {
          zhiwei: "Java后端开发",
          name: "杭州最田源科技有限公司",
          address: "杭州",
          xinzi: "12-15K",
          time: "2021-07-18更新",
        },
        {
          zhiwei: "Java后端开发",
          name: "杭州最田源科技有限公司",
          address: "杭州",
          xinzi: "12-15K",
          time: "2021-07-18更新",
        },
        {
          zhiwei: "Java后端开发",
          name: "杭州最田源科技有限公司",
          address: "杭州",
          xinzi: "12-15K",
          time: "2021-07-18更新",
        },
        {
          zhiwei: "Java后端开发",
          name: "杭州最田源科技有限公司",
          address: "杭州",
          xinzi: "12-15K",
          time: "2021-07-18更新",
        },
      ],
      userData: {},
      userInfo: {},
      jobList: [],
      start: null,
      userJobId: null,
      end: null,
    };
  },
  created() {
    this.getUserData();
    this.getUserInfo();
    this.getJobList();
    this.getWantJob();
  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    toDetail(id) {
      // console.log(id);
      this.$router.push({
        path: "/positiondetail",
        query: {
          id: id,
          userJobId: this.userJobId,
          enderType: "search",
        },
      });
    },

    setTime() {
      this.batchForm.startDate = this.start || "";
      this.batchForm.endDate = this.end || "";
      this.batchDialogVisible = true;
    },
    toliucheng(formName) {
      //   let y = this.batchForm.startDate.getFullYear();
      //   let m = this.batchForm.startDate.getMonth() + 1;
      //   let d = this.batchForm.startDate.getDate();
      // 	let y1 = this.batchForm.endDate.getFullYear();
      //   let m1 = this.batchForm.endDate.getMonth() + 1;
      //   let d1 = this.batchForm.endDate.getDate();
      //   this.batchForm.startDate = y + "-" + m + "-" + d;
      //   this.batchForm.endDate = y1 + "-" + m1 + "-" + d1;

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.changeWorkTime("post", this.batchForm).then((res) => {
            // console.log(res);
            if (res.code == 10200) {
              this.$message({
                message: "设置成功",
                type: "success",
              });
              this.batchForm.startDate = "";
              this.batchForm.endDate = "";
            }
            this.getWantJob();
            this.batchDialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },

    getUserData() {
      //获取用户数据
      this.$api.getUserData("get").then((res) => {
        this.userData = res.data;
      });
    },
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.userInfo = res.data.user;
      });
    },
    getWantJob() {
      //获取求职意向
      this.$api.getWantJob("get").then((res) => {
        this.wantJob = res.data;
        if (res.data.length > 0) {
          this.start = res.data[0].startDate;
          this.end = res.data[0].endDate;
        } else {
          this.start = null;
          this.end = null;
        }
      });
    },
    getJobList() {
      this.$api.getWantJob("get").then((res) => {
        this.userJobId = res.data[0].id;
        this.$api
          .getJobList("get", {
            isHand: 0,
            userJobId: res.data[0].id,
            pageNo: 1,
            pageSize: 5,
          })
          .then((res) => {
            this.jobList = res.data;
          });
      });
    },
    closebatchDialogbox() {
      this.batchDialogVisible = false;
    },
    changeWorkStatus() {
      //修改工作状态
      this.$api.changeWorkStatus("post").then((res) => {
        if (res.code == 10200) {
          this.$message({
            message: "设置成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "error",
          });
        }
        this.getUserData();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gs-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px 30px 30px;
  background-color: #444c5f;
  /deep/.el-divider {
    background: rgb(248 247 252 / 10%);
  }
  /deep/.el-divider--vertical {
    height: 60px;
  }
  .gi-left {
    display: flex;
    // align-items: center;
    flex: 1;
    .gl-pic {
      margin-right: 10px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 50px;
      }
    }
    .gl-info {
      color: #bcbdbe;
      line-height: 2.2;
      div:first-child {
        color: #ffffff;
        span {
          border: 1px solid #ff8400;
          color: #ff8400;
          font-size: 14px;
          padding: 1px 10px;
          border-radius: 20px;
        }
      }
      .zaizhi {
        .zli:hover {
          cursor: pointer;
        }
        span {
          background-color: #646d83;
          color: #ffffff;
          font-size: 14px;
          padding: 2px 6px;
          border-radius: 2px;
        }
      }
      span {
        margin: 0 3px;
      }
    }
  }
  .gi-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0 50px;
    .gr-main:hover {
      cursor: pointer;
    }
    .gr-main {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      flex-direction: column;
      color: #ffffff;
      div:last-child {
        color: #bcbdbe;
      }
    }
  }
}
.closeicon {
  position: absolute;
  top: -40px;
  right: 0;
  font-size: 26px;
  color: #f3f3f3;
}
.batch-box-out {
  .batch-box {
    position: relative;
    .wb-tit {
      padding: 17px 0;
      text-align: center;
      background-color: #f3f3f3;
      color: #000000;
      font-weight: 600;
    }
    /deep/ .el-input__inner,
    /deep/ .el-textarea__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
    }
    .frombox {
      padding: 50px 80px 50px 50px;
      .demo-ruleForm {
        .btn-next {
          width: 100%;
          height: 40px;
          background-color: #00bcff;
        }
      }
    }
  }
}

.tuijian {
  margin-top: 30px;
  .t-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #111111;
    div:last-child {
      color: #04bdff;
    }
    .more:hover {
      cursor: pointer;
    }
  }

  .t-two {
    color: #666666;
    margin-top: 10px;
  }
}
// .boderline{
// 		width: 100%;
// 		border-right: 1px solid #F3F3F3;
// 		border-left: 1px solid #F3F3F3;
// 		border-top: 1px solid #F3F3F3;

// 	}
/deep/.el-table td,
/deep/.el-table th {
  text-align: center;
  color: #333333;
  font-size: 14px;
}
.zhang {
  color: #ff8400;
}
.time {
  color: #666666;
}
</style>
